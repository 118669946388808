import * as React from "react"
import type { HeadFC } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import { SectionWrapper, Node } from "../../components/Sections"
import { HeadingTitle } from "../../components/styling/Heading"
import BorderWrapper, {
  MobileHideWrapper,
} from "../../components/styling/BorderWrapper"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from "react-medium-image-zoom"
import Video from "../../components/styling/Video"

const title = "Slime Alchemist"

const headings = [
  { title: "Wireframes", id: "Wireframes" },
  { title: "Final Product", id: "Final-Product" },
]

const summmary: Summary = {
  headings: headings,
  details: [
    "2023 Steam Release",
    <a href="https://store.steampowered.com/app/2567800/Slime_Alchemist/">
      Slime Alchemist Steam Page
    </a>,
  ],
  role: ["UI / UX Designer"],
  tools: ["Figma", "Affinity Designer", "Photoshop"],
  extraContent: (
    <Node.multiCol
      col1={
        <BorderWrapper style={{ width: "100%" }}>
          <Video
            videoSrc="https://www.youtube.com/embed/Lke-ISNU-c8"
            title="YouTube video player"
          />
        </BorderWrapper>
      }
      col2={
        <MobileHideWrapper>
          <Zoom>
            <StaticImage
              src="../../images/slime/CraftingMockup.jpg"
              alt="A mock up from Slime Alchemist showing the crafting screen. On the left and bottom side is a list of items, in the center is the main crafting section, and the right side is an expanded information section giving details of one of the cards. The mock up is depicting a 'Paladin card' being prepared to craft by sacrificing one other essence in the center crafting section. Currently a Berserker essence is highlighted showing a card pool of Leap Slap, Furious Rush, And Empowering Strike."
              loading="eager"
            />
          </Zoom>
        </MobileHideWrapper>
      }
    />
  ),
}

const SlimePage = () => {
  return (
    <>
      <Layout>
        <SectionWrapper
          as="article"
          heading={
            <HeadingTitle
              name={title}
              subtitle="/overviews/slime-alchemist"
              summary={summmary}
            />
          }
        >
          <Node.p>
            Slime Alchemist is a roguelike deckbuilder with turn-based tatical
            combat that's currently in early access on steam! I had a lot of fun
            working with a more playful style than my usual work and I'd love to
            get another chance to work with the developer.
          </Node.p>
          <Node.p style={{ marginTop: "10px" }}>
            I was brought on to this project to suggest some of the more
            impactful UX improvements that the developer could make, as well as
            create a some assets that fit with the existing style of the
            project. Here's some of the work that I contributed to the project:
          </Node.p>
          <BorderWrapper style={{ marginTop: "20px" }} caption="HUD examples">
            <Zoom>
              <StaticImage
                src="../../images/slime/HUDExamples.png"
                alt="A series 6 different variations of the HUD, each representing different states in gameplay. The first 4 variations show how the 2 extra status bars (that are attached on the side of the main health bar shaped like a slime) toggle between filled and empty states. The 5th variation shows what one of the extra status bars look like when damage has been taken. The 6th variation shows the two locations inventory and buffs would appear separate from each other; inventory attached to the top of the slime, and buffs right under the health number in the middle of the main health bar."
              />
            </Zoom>
          </BorderWrapper>
          <BorderWrapper
            style={{ marginTop: "20px" }}
            caption="Essence highlighting workflow"
          >
            <Zoom>
              <StaticImage
                src="../../images/slime/HighlightingWorkflow.png"
                alt="This image shows 5 frames of the main crafting menu to display how the highlighted essence section functions. There's 3 frams in a row above the last 2 in a row below. The first frame shows an empty highlighted essence section and the player clicking on an icon in the inventory. The second frame shows the clicked icon's additional details in the highlighted essence section, as the player clicks on the second icon in the inventory. The 3rd frame looks similar to the second, but with the details of the second icon now in the highlighted essence section. The 4th frame branches off from the 2nd frame and instead shows the player hovering one of the cards in the card pool, titled with the filler text 'lorem', of the highlighted 'Beserker' essence. The 5th frame shows the card lorem's art and details appearing below the card pool."
              />
            </Zoom>
          </BorderWrapper>
          <BorderWrapper
            style={{ marginTop: "20px" }}
            caption="Main menu mockup"
          >
            <Zoom>
              <StaticImage
                src="../../images/slime/MainMenu.png"
                alt="A simple image showing the Slime Alchemist logo in the top left corner, and buttons in ordered list below it as placeholder menu items. The rest of the screen is blank, but would display a gameplay loop in the game itself."
              />
            </Zoom>
          </BorderWrapper>
        </SectionWrapper>
      </Layout>
    </>
  )
}

export default SlimePage

export const Head: HeadFC = () => <Seo title={title} />
